<template>
  <section class="mt-4">
    <nav aria-label="breadcrumb">
      <ol class="breadcrumb">
        <li class="breadcrumb-item"><router-link to="/" class="link">Home</router-link></li>
        <li class="breadcrumb-item">{{ curPath }}</li>
      </ol>
    </nav>
  </section>
</template>

<script>
import router from "../router/"
export default {
  data() {
    return {
      curPath: '',
    }
  },

  mounted() {
    this.curPath = router.currentRoute.name
    // console.log(router.currentRoute)
  }
}
</script>

<style scoped>
.breadcrumb {
  background-color: transparent;
}
.link {
  color: #7CA297;
  text-decoration: none;
}
</style>