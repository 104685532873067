<template>
  <footer class="footer pt-5 pb-4 mt-5">
    <!-- <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 290"><path fill="#7CA297" fill-opacity="0.56" d="M0,128L80,149.3C160,171,320,213,480,208C640,203,800,149,960,154.7C1120,160,1280,224,1360,256L1440,288L1440,320L1360,320C1280,320,1120,320,960,320C800,320,640,320,480,320C320,320,160,320,80,320L0,320Z"></path></svg> -->
    <!-- <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 270"><path fill="#7CA297" fill-opacity="0.56" d="M0,192L80,197.3C160,203,320,213,480,202.7C640,192,800,160,960,160C1120,160,1280,192,1360,208L1440,224L1440,320L1360,320C1280,320,1120,320,960,320C800,320,640,320,480,320C320,320,160,320,80,320L0,320Z"></path></svg> -->
    <div class="container">
      <div class="row">
        <div class="col-md-3">
          <img src="@/assets/img/logo.png" alt="logo" class="logo" />
          <address class="small">
            {{ address }} <br/>
            {{ mail }} <br/>
          </address>
        </div>
        <div class="col-md-3">
          <h4>Social Media</h4>
          <ul class="list small">
            <li><a href="https://tiktok.com/@binaroom.id" class="text-white" target="_blank"><i class="fab fa-tiktok"></i> Tiktok</a></li>
            <li><a href="https://instagram.com/binaroom.id" target="_blank" class="text-white"><i class="fab fa-instagram"></i> Instagram</a></li>
            <li><a href="https://www.youtube.com/channel/UC1kCnoTTQtYXmotd8nT1u3Q" target="_blank" class="text-white"><i class="fab fa-youtube"></i> YouTube</a></li>
            <li><a href="https://twitter.com/binaroomID" target="_blank" class="text-white"><i class="fab fa-twitter"></i> Twitter</a></li>
          </ul>
        </div>
        <div class="col-md-3">
          <h4>Contact</h4>
          <ul class="list small">
            <li><a href="mailto:info@binaroom.id" target="_blank" class="text-white"><i class="fa fa-envelope"></i> Mail</a></li>
            <li><a href="https://api.whatsapp.com/send?phone=6282119908623" target="_blank" class="text-white"><i class="fab fa-whatsapp"></i> WhatsApp</a></li>
          </ul>
        </div>
        <div class="col-md-3">
          <h4>Link</h4>
          <ul class="list small">
            <li><router-link to="/about">about</router-link></li>
            <li><router-link to="/catalog">catalog</router-link></li>
            <li><router-link to="/contact">contact</router-link></li>
            <li><router-link to="">blog</router-link></li>
          </ul>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12 mt-5 pt-4 border-top">
          <div class="text-center">
            &copy; {{ year }}. PT.Binaroom Kreatif Abadi.
            <!-- &copy; 2020. Made with <i class="fa fa-heart"></i> by
            <strong><router-link :to="{name: 'About'}" class="link color-2">binaroom</router-link></strong>. -->
          </div>
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
export default {
  data() {
    return {
      year: new Date().getFullYear(),
      address: `Perum Bumi Malayu Asri
                Jalan Raya Samarang
                Kab.Garut 44151`,
      mail: `info@binaroom.id`,
    }
  },
  mounted() {
    this.showYear()
  },
  methods: {
    showYear() {
      let y = new Date()
      this.year = y.getFullYear()
    }
  }
}
</script>

<style scoped>
.footer {
  width: 100%;
  font-size: 1.5vh;
  background-color: #7CA297;
  color: #fff;
}
.footer .teks {
  /* background-color: rgba(124, 162, 151, .56); */
  background-color: #7CA297;
}
.footer .link {
  text-decoration: none;
  color: #fff;
}
.footer .link:hover {
  text-decoration: underline;
}

.footer ul.list {
  list-style-type: none;
  padding-left: 0;
  line-height: 2.9vh;
}
.footer ul li > a {
  text-decoration: none;
  color: #fff;
}
.footer ul li > a:hover {
  text-decoration: none;
}

.footer .logo {
  width: 5vh;
}

address {
  white-space:pre-line
}
</style>
